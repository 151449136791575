<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-card>

            <v-toolbar small color="white" flat>

                <v-toolbar-title>Retención emitida</v-toolbar-title>

                <v-divider class="mx-2 " inset vertical>
                </v-divider>

                <v-btn color="primary" small class="ma-2 white--text" v-if="retencion.status_id == 'PMNT_NOT_PAID'">
                    <v-icon left>mdi-send</v-icon>
                    Completar
                </v-btn>

                <!-- <v-btn color="primary" small class="ma-2 white--text" v-if="retencion.status_id == 'PMNT_SENT'"
                    @click="descargar()">
                    <v-icon left>mdi-file-pdf</v-icon>
                    Comprobante
                </v-btn> -->

                <v-menu offset-y v-if="retencion.status_id == 'PMNT_SENT'">
                    <template v-slot:activator="{ on, props }">
                        <v-btn color="blue" dark small v-bind="props" v-on="on">
                            <v-icon>mdi-file-download</v-icon>
                            Comprobante Electrónico
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, index) in getMenuComprobante" :key="index" :href="item.accion">
                            <v-list-item-title><v-icon>{{ item.icon }}</v-icon> {{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-btn color="secondary" small class="ma-2 white--text" v-if="retencion.status_id == 'PMNT_SENT'"
                    @click="anularRetencion()">
                    <v-icon left>mdi-cancel</v-icon>
                    Anular
                </v-btn>




                <v-spacer></v-spacer>


            </v-toolbar>



            <v-row>
                <v-col class="pl-12 pt-6 pb-6 pr-12">
                    <v-row>
                        <v-col sm="6" md="6" class="pt-0 pb-0">
                            <v-text-field label="Numero" class="md-6 white--text" v-model="retencion.ret_numero" small
                                :readonly="estaEnviado">
                            </v-text-field>
                        </v-col>

                        <v-col sm="6" md="6" class="pt-0 pb-0 ">
                            <v-text-field label="Fecha" class="md-6 white--text" v-model="retencion.effective_date" small
                                :readonly="estaEnviado">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col sm="6" md="6" class="pt-0 pb-0 ">
                            <v-text-field label="Proveedor" class="md-6 white--text" :readonly=estaEnviado
                                v-model="retencion.proveedor + ' - ' + retencion.party_id" :disabled="estaAnulado == true">
                            </v-text-field>
                        </v-col>

                        <v-col sm="6" md="6" class="pt-0 pb-0 ">

                            <v-text-field label="Emisor" class="md-6 white--text" :readonly=estaEnviado
                                v-model="retencion.emisor + ' - ' + retencion.party_id_from"
                                :disabled="estaAnulado == true">
                            </v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col sm="6" md="6" class="pt-0 pb-0">
                            <v-text-field label="Estado" class="md-6" v-model="retencion.estado" :readonly="estaEnviado">
                            </v-text-field>
                        </v-col>

                        <v-col sm="6" md="6" class="pt-0 pb-0">
                            <v-text-field label="Factura" class="md-6" v-model="retencion.invoice_number"
                                :readonly="estaEnviado">
                            </v-text-field>
                        </v-col>
                    </v-row>



                </v-col>

            </v-row>
            <v-row>
                <v-col>
                    <v-data-table :loading="loadingTable" hide-default-footer :items=itemsRetencion :headers=headerItems
                        class="px-8 py-2">
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
        <v-card>
            <v-row>
                <v-col cols=12 md="12">
                    <v-expansion-panels accordion v-model="panel">
                        <v-expansion-panel class='px-0 pb-0'>
                            <v-expansion-panel-header color="blue-grey lighten-5">
                                ASIENTOS CONTABLES
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <AccountingEntries :asientos=asientos />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-card>



    </div>
</template>


<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ExpandableFilters from '../general/ExpandableFilters'
import AccountingEntries from '../general/AccountingEntries'
import Vue from 'vue'

export default {
    name: "RetencionEmitidaFormComponent",
    components: {
        ExpandableFilters,
        AccountingEntries
    },
    data: () => ({


        options: {},
        dialog: false,
        notifications: false,
        sound: true,
        widgets: true,

        retencion: {
            effective_date: "",
            proveedor: "",
            emisor: "",
            estado: "",
            ret_numero: "",
            status_id: "",
            estado: "",
            invoice_number: "",
            payment_id: "",
            party_id: "",
            party_id_from: "",
            retencion_id: "",
            clave_acceso: ""
        },

        itemsEstado: [
            { estadoId: "PMNT_SENT", estadoNombre: "Listo" },
            { estadoId: "PMNT_NOT_PAID", estadoNombre: "En proceso" },
            { estadoId: "PMNT_VOID", estadoNombre: "Canceladas" }
        ],
        asientos: [],
        itemsRetencion: [],
        totalesRetencion: [],
        headerItems: [
            { text: "No.", value: "deduction_id", align: "start" },
            { text: "Cuenta", value: "account_name", align: "start" },
            { text: "Codigo", value: "codigo_impuesto", align: "end" },
            { text: "Base imponible", value: "base_imponible", align: "end" },
            { text: "%Retenido", value: "porcentaje", align: "end" },
            { text: "Valor", value: "amount", align: "end" },
        ],
        filters: [
            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model: '',
                label: 'Número',
                type: 'input_text'
            },
            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-3 pl-0 pl-sm-2',
                v_model: '',
                label: 'Proveedor',
                type: 'input_text'
            },
            {
                cols: 12,
                class: 'py-0 py-md-2 col-md-3 pr-0 pl-0 pr-sm-2 mb-1',
                v_model: [],
                label: 'Fecha',
                type: 'input_date_time',
                clearable: true,
            },

            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model: '',
                label: 'Estado',
                type: 'input_select',
                text: 'name',
                value: 'status_id',
                clearable: true,
                items: [
                    { 'status_id': 'PMNT_SENT', 'name': 'Listo' },
                    { 'status_id': 'PMNT_NOT_PAID', 'name': 'En proceso' },
                    { 'status_id': "PMNT_VOID", 'name': "Canceladas" }
                ],
            },
        ],
        currentPage: 0,
        pageCount: 1,
        itemsPerPage: 30,
        searchNumber: "",
        searchFechaInv: "",
        searchEstado: "",
        searchCliente: "",
        overlay: false,
        panel: '',
        required: [
            v => !!v || 'El campo es requerido'
        ]
    }),
    watch: {
        options: {
            handler() {
                this.cargarLista(false)
            },
            deep: true
        }
    },
    computed: {
        ...mapState("master", ["loadingTable", "user", "tenantId", "paramAlertQuestion"]),
        ...mapGetters("access", ["btnAbrirCaja"]),
        updateHeader() {
            //return (this.cabecera.invoice_type_id =='PAYROL_INVOICE' || (this.cabecera.invoice_type_id =='INVOICE_HONORARIOS' && this.cabecera.status_id =='INVOICE_READY'))
        },
        estaAnulado() {
            if (this.retencion.status_id == 'PMNT_VOID') {
                return true;
            }
            return false
        },
        estaEnviado() {
            if (this.retencion.status_id == 'PMNT_SENT') {
                return true;
            }
            return false;
        },
        getUrlRide() {
            if (this.retencion.clave_acceso != "") {
                return "https://sufactura.ec/descargar-ride?claveAcceso=" + this.retencion.clave_acceso
            }
            return "#"
        },
        getUrlXml() {
            if (this.retencion.clave_acceso != "") {
                return "https://sufactura.ec/descargar-xml2?claveAcceso=" + this.retencion.clave_acceso
            }
            return "#"
        },
        getMenuComprobante() {
            let menu = [
                { title: "Descargar PDF", icon: "mdi-file-pdf", accion: this.getUrlRide },
                { title: "Descargar XML", icon: "mdi-xml", accion: this.getUrlXml },
                //{ title: "Descargar zip", icon: "mdi-folder-zip", accion: "" }
            ];
            return menu;
        }




    },
    methods: {
        ...mapMutations("master", ["setUrl", "setOverlay", "setMenu", "setLoadingTable", "setTitleToolbar"]),
        ...mapActions("master", ["requestApi", "alertNotification"]),

        cargarRetencion() {

            this.setUrl("retencion-emitida/" + this.$route.params.paymentId);
            this.requestApi({
                method: "GET",
                data: {},
            }).then(res => {

                this.asientos = res.data.asientos.sort((a, b) => { return a.acctg_trans_entry_seq_id - b.acctg_trans_entry_seq_id })
                this.retencion = res.data.retencion;
                this.itemsRetencion = res.data.items;
                //this.totalesRetencion = res.data.totales;
                //this.pagos = res.data.pagos
                // this.numeroFactura.numero = res.data.cabecera.invoice_number
                // this.numeroFactura.ptoEmision = res.data.cabecera.codigo_punto_emision
                // this.numeroFactura.establecimiento = res.data.cabecera.codigo_establecimiento
                // this.numeroFactura.claveAcceso = res.data.cabecera.autorizacion_sri
                this.dialog = true

            });
        },
        anularRetencion() {

        },


        handlePageChange(value) {
            this.currentPage = value
            this.cargarRetencion(false);
        },


    },
    mounted() {
        this.setTitleToolbar('RETENCION EMITIDA')
        this.cargarRetencion();
    },
}
</script>